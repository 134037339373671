const initialState = () => {
    return {
        processingCartItems: [],
        cartItemsCount: 0,
    };
};

const getters = {
    cartItemProcessing: (state) => (cartItems) => state.processingCartItems.includes(cartItems),
    cartTotalProcessing: (state) => state.processingCartItems.length > 0,
    cartItemsCount: (state) => state.cartItemsCount,
};

const mutations = {
    ADD_CART_ITEM_PROCESSING(state, cartItemId) {
        if ( ! state.processingCartItems.includes(cartItemId)) {
            state.processingCartItems.push(cartItemId);
        }
    },
    REMOVE_CART_ITEM_PROCESSING(state, cartItemId) {
        state.processingCartItems = state.processingCartItems.filter(
            (item) => item !== cartItemId
        );
    },
    ADD_CART_ITEM(state, amount) {
        state.cartItemsCount += Number(amount);
    },
    SET_CART_ITEMS(state, amount) {
        state.cartItemsCount = Number(amount);
    }
};

const actions = {
    async addProductToQuote({state, commit, dispatch}, productPayload: object) {
        const {data} = await this.$solarClient.post(
            '/api/checkout/quote/addProductToQuote',
            {
                cartItem: productPayload,
            },
            {timeout: 25000}
        );

        dispatch("addCartItemsCount", productPayload['qty'] || 1);

        const servicesData = {
            cartItemId: data['item_id'],
            productSku: data['sku'],
            index: 0,
        };

        dispatch("ProductServices/addServiceToQuote", servicesData, {root: true});
    },
    addProductToQuoteProcessing({commit}, cartItemId) {
        commit("ADD_CART_ITEM_PROCESSING", cartItemId);
    },
    removeProductToQuoteProcessing({commit}, cartItemId) {
        commit("REMOVE_CART_ITEM_PROCESSING", cartItemId);
    },
    setCartItemsCount({commit}, amount) {
        commit("SET_CART_ITEMS", amount);
    },
    addCartItemsCount({commit}, amount) {
        commit("ADD_CART_ITEM", amount);
    }
};

const state = initialState();

const CheckoutQuote = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default CheckoutQuote;
