import Vue from 'vue';

const initialState = () => {
    return {
        cartItemId: '',
        productServices: {},
        selectedProductServices: {}
    };
};

const getters = {
    cartItemId: (state) => state.cartItemId,
    productServices: (state) => state.productServices,
    selectedProductServices: (state) => state.selectedProductServices,
};

const mutations = {
    SET_CART_ITEM_ID(state, payload) {
        Vue.set(state, 'cartItemId', payload);
    },
    SET_PRODUCT_SERVICES(state, payload) {
        Vue.set(state.productServices, payload.index, {
            [payload.productSku]: payload.services
        });
    },
    SET_SELECTED_PRODUCT_SERVICES(state, payload) {
        Vue.set(state.selectedProductServices, payload.index, {
            [payload.productSku]: payload.services
        });
    },
    ADD_SELECTED_PRODUCT_SERVICES(state, payload) {
        switch (payload.serviceType) {
            case 'checkbox':
                if (payload.value) {
                    state.selectedProductServices[payload.index] = {
                        ...state.selectedProductServices[payload.index],
                        [payload.productSku]: {
                            ...state.selectedProductServices[payload.index][payload.productSku],
                            [payload.service.sku]: payload.service
                        }
                    };
                    return;
                } else {

                    state.selectedProductServices[payload.index] = {
                        ...state.selectedProductServices[payload.index],
                    };

                    Vue.delete(state.selectedProductServices[payload.index][payload.productSku], payload.service.sku);
                }

                if (payload.service.children) {
                    for (const object of Object.values(payload.service.children)) {
                        state[payload.index].selectedProductServices = {
                            ...state[payload.index].selectedProductServices,
                        };

                        Vue.delete(state.selectedProductServices[payload.index][payload.productSku], object['sku']);

                    }

                    Vue.delete(state.selectedProductServices[payload.index][payload.productSku], payload.service.sku);
                }
                break;
            case 'radio':
                if (payload.value) {
                    const sameOptionGroupElement = Object.values(state.selectedProductServices[payload.index][payload.productSku]).find(key => key['option_group'] === payload.service['option_group']);

                    if (sameOptionGroupElement) {
                        const hasChildren = state.selectedProductServices[payload.index][payload.productSku][sameOptionGroupElement['sku']]['children'];
                        if (hasChildren) {
                            Object.values(hasChildren).forEach((child) => {
                                delete state.selectedProductServices[payload.index][payload.productSku][child['sku']];

                                state.selectedProductServices[payload.index] = {
                                    ...state.selectedProductServices[payload.index],
                                    [payload.productSku]: {
                                        ...state.selectedProductServices[payload.index][payload.productSku],
                                        [payload.service.sku]: payload.service
                                    }
                                };
                            });
                        }

                        delete state.selectedProductServices[payload.index][payload.productSku][sameOptionGroupElement['sku']];

                        state.selectedProductServices[payload.index] = {
                            ...state.selectedProductServices[payload.index],
                            [payload.productSku]: {
                                ...state.selectedProductServices[payload.index][payload.productSku],
                                [payload.service.sku]: payload.service
                            }
                        };

                    } else {
                        state.selectedProductServices[payload.index] = {
                            ...state.selectedProductServices[payload.index],
                            [payload.productSku]: {
                                ...state.selectedProductServices[payload.index][payload.productSku],
                                [payload.service.sku]: payload.service
                            }
                        };
                    }
                    return;
                }
                break;
            default:
                Vue.set(state.selectedProductServices[payload.index][payload.productSku], payload.service.sku, payload.service);
                break;
        }
    }
};

const actions = {
    async getAllProductServices({commit}, payload: object): Promise<void> {
        const productSku = payload['productSku'];
        const prefillServices = payload['quoteItemServices'];
        let availableServices = [];

        let servicesObject = {};
        let selectedServices = {};

        try {
            const {data} = await this.$solarClient.get(`/api/catalog/product/services/product/sku/${productSku}`);
            availableServices = data.sort((a, b) => a.sort_order - b.sort_order);
        } catch (e) {
            console.log(e);
        }

        for (const service of availableServices) {
            if (prefillServices && service.default_selected) {
                selectedServices = {
                    ...selectedServices,
                    [service.sku]: service
                };
            }

            servicesObject = {
                ...servicesObject,
                [service.sku]: service
            };
        }

        commit('SET_PRODUCT_SERVICES', {
            productSku: productSku,
            services: servicesObject,
            index: payload['index'],
        });

        commit('SET_SELECTED_PRODUCT_SERVICES', {
            productSku: productSku,
            services: selectedServices,
            index: payload['index'],
        });

        prefillServices.forEach((serviceSku: string) => {
            const sameAvailableService = availableServices.find(item => item.sku === serviceSku);
            if ( ! sameAvailableService) {
                availableServices.forEach((availableService) => {
                    if (availableService.children && availableService.children[serviceSku]) {
                        Vue.set(state.selectedProductServices[payload['index']][productSku], serviceSku, availableService.children[serviceSku]);
                    }
                });
            } else {
                Vue.set(state.selectedProductServices[payload['index']][productSku], serviceSku, sameAvailableService);
            }
        });
    },
    async addServiceToQuote({state}, payload): Promise<void> {
        if(state.selectedProductServices.hasOwnProperty(payload.index)) {
            await this.$solarClient.post(
                '/api/catalog/product/services/addServicesToQuoteItem', {
                    cartItem: {
                        cart_item_id: payload.cartItemId,
                        services: Object.keys(state.selectedProductServices[payload.index][payload.productSku] || {})
                    }
                },
                {timeout: 25000}
            );
        }
    },
};

const state = initialState();

const ProductServices = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default ProductServices;
