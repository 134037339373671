import {appLocalStorage} from '../../checkout/services';

const initialState = () => {
    return {
        stores: appLocalStorage.getItem('stores-whatsapp', {}),
        query: appLocalStorage.getItem('stores-query', ''),
    };
};

const getters = {
    stores: (state: object) => state['stores'],
    query: (state: object) => state['query'],
};

const mutations = {
    SET_STORES(state: object, payload: object): void {
        appLocalStorage.setItem('stores-whatsapp', payload);
        state['stores'] = payload;
    },
    SET_QUERY(state: object, payload: object): void {
        appLocalStorage.setItem('stores-query', payload);
        state['query'] = payload;
    },
};

const actions = {
    setStores({commit}, payload: object) {
        commit('SET_STORES', payload);
    },
    setQuery({commit}, payload: string) {
        commit('SET_QUERY', payload);
    },
};

const state = initialState();

const FindStores = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default FindStores;
