('.header__navigation nav>ul').dropDown(el => {
    el.classList.add('--active');
}, el => {
    el.classList.remove('--active');
}, 200);


document.addEventListener('DOMContentLoaded', () => {
    const modalElements = document.querySelectorAll('[data-reveal]');
    const modalOpenElements = document.querySelectorAll('[data-open]');

    Array.from(modalOpenElements).forEach(el => {
        el.addEventListener('click', e => {
            const modalAttribute = el.getAttribute('data-open');
            const modalEl = document.getElementById(modalAttribute);
            if (modalEl) {
                modalEl.style.display = 'block';
                document.body.classList.add('no-scroll');

                window.addEventListener('click', closePopup.bind(modalAttribute), false);
                window.addEventListener('keyup', closePopup.bind(modalAttribute), false);
            }
        });
    });

    let closePopup = function (e) {
        if (e.target.classList.contains('modal__overlay') || e.target.classList.contains('close-button') || e.which === 27) {
            document.body.classList.remove('no-scroll');
            document.getElementById(this).style.display = 'none';
            document.querySelector('.js-off-canvas-overlay.is-visible').classList.remove('is-visible');
        }
    };

    Array.from(modalElements).forEach(el => {
        const modalHTMLContent = el.innerHTML;
        el.innerHTML = '';

        const modalOverlay = document.createElement('div');
        modalOverlay.classList.add('modal__overlay');

        const modalWrapper = document.createElement('div');
        modalWrapper.classList.add('modal__wrapper');

        const modalContent = document.createElement('div');
        modalContent.classList.add('modal-content');
        modalContent.innerHTML = modalHTMLContent;

        modalOverlay.appendChild(modalWrapper);
        modalWrapper.appendChild(modalContent);
        el.appendChild(modalOverlay);
    });

  Array.from(document.getElementsByClassName('is-drilldown-submenu-parent')).forEach(firstEl => {
    Array.from(firstEl.getElementsByClassName('nested')).forEach(nestedEl => {
      if (nestedEl.getElementsByClassName('nested').length) {
        Array.from(nestedEl.getElementsByClassName('nested')).forEach(thirdLevel => {
          thirdLevel.parentElement.classList.add('is-drilldown-submenu-parent');
          let backLi = document.createElement('li');
          backLi.innerHTML = '<a> Terug</a>';
          backLi.classList.add('js-drilldown-back');
          thirdLevel.insertBefore(backLi, thirdLevel.children[0]);
        });
      }
    });
  });
});

window.addReadMore = function ($event, height) {
    const descElement = $event.target.parentElement.parentElement;
    const contentBlock = $event.target.parentElement.previousElementSibling;
    const trueHeight = contentBlock.scrollHeight;
    const baseHeight = height ? height : 100;

    if (trueHeight <= baseHeight) {
        descElement.getElementsByClassName('_actions')[0].style.display = 'none';
    }

    if ($event.target.classList.contains('_active')) {
        $event.target.classList.remove('_active');
        contentBlock.classList.add('_filter');
        $event.target.innerText = 'Lees meer';
        descElement.getElementsByClassName('_content')[0].style.maxHeight = `${baseHeight}px`;
        descElement.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        })
    } else {
        $event.target.classList.add('_active');
        contentBlock.classList.remove('_filter');
        $event.target.innerText = 'Lees minder';
        descElement.getElementsByClassName('_content')[0].style.maxHeight = 'fit-content';
    }
}
