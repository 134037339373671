import { appSessionStorage } from '../services';

const initialState = () => {
  return {
    shippingMethod: appSessionStorage.getItem('checkout-shipping-selected-method', {}),
    shippingMethods: [],
    shippingCountries: {},
    loadingShippingMethods: false,
    pblResponse: appSessionStorage.getItem('checkout-pbl-response', null)
  };
};

const getters = {
  shippingMethod: (state) => state.shippingMethod,
  shippingMethods: (state) => state.shippingMethods,
  shippingCountries: (state) => state.shippingCountries,
  loadingShippingMethods: (state) => state.loadingShippingMethods,
  pblResponse: (state) => state.pblResponse,
  pblShippingNextStepAllowed: (state) => {
    return state.pblResponse['allowNextStep'] || false;
  }
};

const mutations = {
  SET_SHIPPING_METHOD: (state, payload) => (state.shippingMethod = payload),
  SET_SHIPPING_METHODS: (state, payload) => (state.shippingMethods = payload),
  SET_SHIPPING_COUNTRIES: (state, payload) => (state.shippingCountries = payload),
  SET_LOADING_SHIPPING_METHODS: (state, payload) => (state.loadingShippingMethods = payload),
  SET_PBL_RESPONSE: (state, response) => (state.pblResponse = response)
};

const actions = {
  async setShippingMethod({ commit, dispatch }, shippingMethod) {
    commit('SET_SHIPPING_METHOD', shippingMethod);
    appSessionStorage.setItem('checkout-shipping-selected-method', shippingMethod);
  },
  async collectShippingMethods({ rootGetters, commit, dispatch }) {
    try {
      const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

      commit('SET_LOADING_SHIPPING_METHODS', true);
      const { data } = await this.$solarClient.post('/api/checkout/shipping/collectShippingMethods', {
        address: _shippingAddress,
      });

      commit('SET_SHIPPING_METHODS', data);
    } catch (err) {
      //
    } finally {
      commit('SET_LOADING_SHIPPING_METHODS', false);
    }
  },
  setShippingCountries({ commit }, payload) {
    try {
      commit(
        'SET_SHIPPING_COUNTRIES',
        payload.sort((a, b) => (a['full_name_locale'] > b['full_name_locale'] && 1) || -1)
      );
    } catch (err) {
      //
    }
  },
  buildShippingRequest({rootGetters}) {
    const shippingAddress = rootGetters['CheckoutAddress/shippingAddress'];
    const customerEmail = rootGetters['CheckoutGlobal/customerEmail'];
    const cartItems: object[] = rootGetters['CheckoutTotals/cartItems'];

    const address = {
      salutation: shippingAddress.prefix || '',
      firstName: shippingAddress.firstname || '',
      surNamePrefix: shippingAddress.middlename || '',
      surName: shippingAddress.lastname || '',
      companyName: shippingAddress.company || '',
      street: shippingAddress.street[0],
      houseNr: String(shippingAddress.street[1]),
      houseNrSuffix: shippingAddress.street[2],
      zipcode: shippingAddress.postcode,
      city: shippingAddress.city,
      country: shippingAddress.country_id,
      email: customerEmail || '',
    };

    const products: object[] = cartItems.flatMap((item: object) => {
      if ((item['extension_attributes']['bundle_items'] || []).length > 0) {
        return item['extension_attributes']['bundle_items'].map((bundleItem: object) => ({
          productnr: bundleItem['sku'],
          quantity: item['qty'],
        }));
      }

      return [{
        productnr: item['extension_attributes']['sku'],
        quantity: item['qty'],
      }]
    });

    let services: object[] = []

    cartItems.forEach((item: object): void => {
      (item['extension_attributes']['services'] || '').split(',').forEach((sku: string): void => {
        if ( ! sku.length) {
          return
        }

        const existingItem: null|object = services.find((row: object): boolean => row['productnr'] === sku)

        if (existingItem) {
          existingItem['quantity'] += 1
          return
        }

        services.push({productnr: sku, quantity: 1})
      })
    })

    const mergedProducts: object[] = [...products, ...services]

    let preSelect = {};

    if (rootGetters['CheckoutShipping/pblResponse']) {
      preSelect = {
        method: rootGetters['CheckoutShipping/pblResponse']['method'] || '',
        deliveryDate: rootGetters['CheckoutShipping/pblResponse']['promisedDeliveryDate'] || '',
        storeId: rootGetters['CheckoutShipping/pblResponse']['store']['identifier'] || ''
      };
    }

    let freeShipping = rootGetters['CheckoutShipping/shippingMethods'].filter((method) => {
      return method['amount'] > 0
    }).length === 0;

    return {
      freeShipping: freeShipping,
      address: address,
      products: mergedProducts,
      preSelect: preSelect
    };
  },
  setPblResponse({commit, dispatch}, payload) {
    commit('SET_PBL_RESPONSE', payload.response)

    let pblData = payload.response['store'] || {};

    if (null !== payload.response) {
      pblData['method'] = payload.response['method'];
      pblData['promisedDeliveryDate'] = payload.response['promisedDeliveryDate'];
    }

    dispatch('CheckoutGlobal/addExtensionAttribute', {
      pbl_selected_store: null !== pblData ? JSON.stringify(pblData) : null
    }, {root: true})
  }
};

const state = initialState();

const CheckoutShipping = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutShipping;
