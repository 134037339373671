const initialState = () => {
    return {
        productsToCompare: []
    };
};

const getters = {
    productsToCompare: (state) => state.productsToCompare,
    isProductInCompare: (state) => (productId) => state.productsToCompare.find((product) => product.product_id === productId),
};

const mutations = {
    SET_PRODUCTS_TO_COMPARE(state, productsToCompare) {
        state.productsToCompare = productsToCompare;
    }
};

const actions = {
    async addProductToCompare({commit}, productPayload) {
        try {
            const {data} = await this.$solarClient.post('/api/catalog/compare',
                {product_id: productPayload},
                {timeout: 25000}
            );
            commit('SET_PRODUCTS_TO_COMPARE', data);

        } catch (e) {
            console.error(e);
        }
    },
    setProductsToCompare({commit}, productsToCompare) {
        commit('SET_PRODUCTS_TO_COMPARE', productsToCompare);
    },
};

const state = initialState();

const ProductItemsCompare = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default ProductItemsCompare;
