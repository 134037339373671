const initialState = () => {
  return {
    isB2B: window.Config['is_b2b'] || false,
  };
};

const getters = {
  getB2BStatus: state => state.isB2B,
};

const mutations = {};

const actions = {};

const state = initialState();

const GlobalData = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default GlobalData;
