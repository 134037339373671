import { appLocalStorage } from '../services';

const initialState = () => {
    return {
        addressDetails: appLocalStorage.getItem('customer-address-details', {
            postcode: '',
            houseNumber: ''
        }),
        loadingAddressDetails: false,
        loadingLocationDetails: false,
        addressDetailsAutocompleted: {},
        addressLocationAutocompleted: {},
    };
};

const getters = {
    addressDetails: (state) => state.addressDetails,
    isLoadingAddressDetails: (state) => state.loadingAddressDetails,
    isLoadingLocationDetails: (state) => state.loadingLocationDetails,
    addressDetailsAutocompleted: (state) => state.addressDetailsAutocompleted,
    addressLocationAutocompleted: (state) => state.addressLocationAutocompleted,
};

const mutations = {
    SET_ADDRESS_DETAILS(state, addressDetails) {
        state.addressDetails = Object.assign(state.addressDetails, addressDetails);
        appLocalStorage.setItem('customer-address-details', state.addressDetails)
    },
    SET_LOADING_ADDRESS_DETAILS(state, isLoading) {
        state.loadingAddressDetails = isLoading;
    },
    SET_ADDRESS_DETAILS_AUTOCOMPLETED(state, addressDetailsAutocompleted) {
        state.addressDetailsAutocompleted = addressDetailsAutocompleted;
    },
    SET_LOADING_LOCATION_DETAILS(state, isLoading) {
        state.loadingLocationDetails = isLoading;
    },
    SET_ADDRESS_LOCATION_AUTOCOMPLETED(state, addressLocationAutocompleted) {
        state.addressLocationAutocompleted = {
            postcode: addressLocationAutocompleted.postcode,
            latitude: addressLocationAutocompleted.latitude,
            longitude: addressLocationAutocompleted.longitude,
        }
    },
};

const actions = {
    async collectAddressDetails({commit}, addressDetails: object): Promise<any> {
        try {
            commit('SET_LOADING_ADDRESS_DETAILS', true);
            commit('SET_ADDRESS_DETAILS', addressDetails);
            const {data} = await this.$solarClient.get(
                `/api/address/autocomplete/details/${addressDetails['postcode']}/${addressDetails['houseNumber']}`,
            )

            commit('SET_ADDRESS_DETAILS_AUTOCOMPLETED', data);
            commit('SET_ADDRESS_LOCATION_AUTOCOMPLETED', data);

            return data;
        } catch (err) {
            return false;
        } finally {
            commit('SET_LOADING_ADDRESS_DETAILS', false);
        }
    },
    async collectLocationDetails({commit, getters}, addressDetails: object): Promise<void> {
        try {
            commit('SET_LOADING_LOCATION_DETAILS', true);
            commit('SET_ADDRESS_DETAILS', addressDetails);
            const {data} = await this.$solarClient.get(
                `/api/address/autocomplete/details/${addressDetails['postcode']}/${addressDetails['houseNumber']}/${addressDetails['houseNumberAddition']}`,
            )

            commit('SET_ADDRESS_LOCATION_AUTOCOMPLETED', data);
        } catch (err) {

        } finally {
            commit('SET_LOADING_LOCATION_DETAILS', false);
        }
    }
};

const state = initialState();

const CustomerAddressAutocomplete = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default CustomerAddressAutocomplete;
