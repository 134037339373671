const initialState = () => {
  return {
    categoryId: null,
    apiResponse: null,
    apiResponsePending: false,
    pageQueries: {}
  }
};

const state = initialState();

const mutations = {
  SET_CATEGORY_ID(state, categoryId) {
    state.categoryId = categoryId;
  },
  SET_API_RESPONSE_PENDING(state, status) {
    state.apiResponsePending = status;
  },
  SET_API_RESPONSE(state, response) {
    state.apiResponse = response;
  }
};

const actions = {
  setCategoryId({commit}, categoryId) {
    commit('SET_CATEGORY_ID', categoryId);
  },
  setApiResponse({commit}, response) {
    commit('SET_API_RESPONSE', response);
  },
  updatePage({dispatch}, page) {
    dispatch('fetchCatalog', {
      pageQueries: {page: page}
    });
  },
  updatePageSize({dispatch}, pageSize) {
    dispatch('fetchCatalog', {
      pageQueries: {page_size: pageSize, page: 1}
    });
  },
  updatePriceFilter({dispatch}, payload) {
    dispatch('fetchCatalog', {
      pageQueries: {minPrice: payload.minPrice, maxPrice: payload.maxPrice, page: 1}
    });
  },
  updatePageSort({dispatch}, sort) {
    let sortString = '';
    Object.entries(sort || {}).forEach(([key, value]) => {
      sortString = `${key} ${value}`
    });

    dispatch('fetchCatalog', {
      pageQueries: {sort: sortString, page: 1}
    });
  },
  filterAction({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filterCode) {
          filter['options'].forEach(option => {
            if (option['label'] === payload.optionLabel) {
              switch (payload.actionType) {
                case 'toggle':
                  option['active'] = !option['active'];
                  break;
                case 'activate':
                  option['active'] = true;
                  break;
                case 'deactivate':
                  option['active'] = false;
                  break;
              }
            }
          });
        }
        return filter;
      }),
      pageQueries: {
        page: 1
      }
    });
  },
  toggleFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'toggle',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  activateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'activate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  deactivateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'deactivate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  singleValueFilter({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filter['code']) {
          filter.options.forEach(o => {
            o['active'] = o['label'] === payload.option['label']
          });
        }
        return filter;
      })
    });
  },
  fetchCatalog({state, commit}, payload) {
    commit('SET_API_RESPONSE_PENDING', true);

    const categoryId = payload['categoryId'] || state.categoryId;
    const filters = payload.filters || (state.apiResponse.filters || []);

    let params = {};
    let queryParams = Object.assign(state.pageQueries, (payload.pageQueries || {}));

    // if ('undefined' === typeof queryParams['page_size']) {
    // queryParams['page_size'] = getters.pageSize(state);
    // }

    if ('undefined' === typeof queryParams['query'] && state.searchedQuery !== '') {
      queryParams['query'] = state.searchedQuery;
    }

    if ('undefined' === typeof queryParams['query'] && state.searchedQuery !== '') {
      queryParams['query'] = state.searchedQuery;
    }

    if (getters.priceFilters(state).length) {
      if ('undefined' === typeof queryParams['minPrice'] && getters.priceFilters(state)[0]['MinimumValue'] > 0) {
        queryParams['minPrice'] = getters.priceFilters(state)[0]['MinimumValue'];
      }

      if ('undefined' === typeof queryParams['maxPrice'] && getters.priceFilters(state)[0]['MaximumValue'] > 0) {
        queryParams['maxPrice'] = getters.priceFilters(state)[0]['MaximumValue'];
      }
    }

    if ('undefined' === typeof queryParams['sort'] && null !== getters.currentSort(state)) {
      let sortString = '';
      Object.entries(getters.currentSort(state) || {}).forEach(([key, value]) => {
        sortString = `${key} ${value}`
      });

      queryParams['sort'] = sortString;
    }

    if (filters.length) {
      let activeFilters = filters.filter(item => {
        if (item.type !== 'attribute') {
          return false;
        }

        let activeOptions = item.options.filter(option => {
          return option.active;
        });

        return activeOptions.length;
      });

      let filterSegments = {};
      activeFilters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.active) {
            if (!Object.prototype.hasOwnProperty.call(filterSegments, filter['facet_code'])) {
              filterSegments[filter['facet_code']] = [];
            }

            filterSegments[filter['facet_code']].push(
                `${option.label}`
            );
          }
        });
      });
      params = Object.assign(filterSegments, params);
    }

    const apiUrl = `/api/catalog/category/${categoryId}/product/list`;

    this.$solarClient.get(
        apiUrl, {
          params: Object.assign(params, queryParams)
        }).then(({data}) => {
      commit('SET_API_RESPONSE', data);

    }).finally(() => {
      commit('SET_API_RESPONSE_PENDING', false);
    });
  }
};

const getters = {
  isLoading: state => {
    return state.apiResponsePending;
  },
  currentPage: state => {
    return state.apiResponse ? state.apiResponse['current_page'] : 1;
  },
  currentSort: state => {
    return state.apiResponse ? state.apiResponse['sorting'] : null;
  },
  totalPages: state => {
    return state.apiResponse ? state.apiResponse['last_page'] : 1;
  },
  pageSize: state => {
    return state.apiResponse ? state.apiResponse['page_size'] : 24;
  },
  productList: state => {
    return state.apiResponse ? (Object.prototype.hasOwnProperty.call(state.apiResponse, 'items') ? state.apiResponse['items'] : []) : [];
  },
  filterList: state => {
    return state.apiResponse ? (Object.prototype.hasOwnProperty.call(state.apiResponse, 'filters') ? state.apiResponse['filters'] : []) : [];
  },
  priceFilters: state => {
    if (state.apiResponse && Object.prototype.hasOwnProperty.call(state.apiResponse, 'filters')) {
      return state.apiResponse.filters.filter(o => {
        return o.type === 'price'
      });
    }
  },
  activeFilters: (state, getters) => {
    return getters.filterList.filter(filter => {
      return filter['type'] !== 'attribute' ? false : filter['is_active'];
    });
  },
  activeCrawlableFilters: (state, getters) => {
    return getters.activeFilters.filter(filter => filter['is_crawlable']);
  },
  totalItemCount: state => {
    return state.apiResponse ? (Object.prototype.hasOwnProperty.call(state.apiResponse, 'total_items') ? state.apiResponse['total_items'] : 0) : 0;
  },
  getApiResponse: state => state.apiResponse,
};

const CatalogGuidedSelling = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CatalogGuidedSelling;
