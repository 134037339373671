import { appLocalStorage } from '../services';

const initialState = () => {
  return {
    billingAddress: appLocalStorage.getItem('checkout-billing-address', {}),
    shippingAddress: appLocalStorage.getItem('checkout-shipping-address', {}),
    billingIsSameAsShipping: appLocalStorage.getItem('checkout-billing-is-same-as-shipping', true),
  };
};

const getters = {
  billingAddress: (state) => (state.billingIsSameAsShipping ? state.shippingAddress : state.billingAddress),
  shippingAddress: (state) => state.shippingAddress,
  billingIsSameAsShipping: (state) => state.billingIsSameAsShipping,
  getAddressData: (state, getters) => {
    return getters.convertAddressData;
  },
  convertAddressData: (state) => {
    let shippingExtensionAttributes = {};
    let billingExtensionAttributes = {};

    if (state.shippingAddress.hasOwnProperty('custom_attributes')) {
      state.shippingAddress['custom_attributes'].forEach(function (attribute) {
        shippingExtensionAttributes[attribute['attribute_code']] = attribute['value'];
      });
    }

    let shippingAddress = {
      city: state.shippingAddress['city'] || '',
      company: state.shippingAddress['company'] || '',
      country_id: state.shippingAddress['country_id'] || '',
      customerAddressId: state.shippingAddress['id'] || null,
      firstname: state.shippingAddress['firstname'] || '',
      middlename: state.shippingAddress['middlename'] || '',
      lastname: state.shippingAddress['lastname'] || '',
      postcode: state.shippingAddress['postcode'] || '',
      prefix: state.shippingAddress['prefix'] || '',
      vat_id: state.shippingAddress['vat_id'] || null,
      region: null,
      regionCode: null,
      saveInAddressBook: state.shippingAddress['newAddress'] ? 1 : null,
      street: 'string' === typeof state.shippingAddress['street'] ? state.shippingAddress['street'].split(/\n/) : state.shippingAddress['street'] || ['', '', ''],
      telephone: state.shippingAddress['telephone'],
      extension_attributes: shippingExtensionAttributes,
    };

    if (!state.billingIsSameAsShipping && state.billingAddress.hasOwnProperty('custom_attributes')) {
      state.billingAddress['custom_attributes'].forEach(function (attribute) {
        billingExtensionAttributes[attribute['attribute_code']] = attribute['value'];
      });
    }

    let billingAddress = state.billingIsSameAsShipping
      ? { ...shippingAddress }
      : {
          city: state.billingAddress['city'] || '',
          company: state.billingAddress['company'] || '',
          country_id: state.billingAddress['country_id'] || '',
          customerAddressId: state.billingAddress['id'] || null,
          firstname: state.billingAddress['firstname'] || '',
          middlename: state.billingAddress['middlename'] || '',
          lastname: state.billingAddress['lastname'] || '',
          postcode: state.billingAddress['postcode'] || '',
          prefix: state.billingAddress['prefix'] || '',
          vat_id: state.billingAddress['vat_id'] || null,
          region: null,
          regionCode: null,
          saveInAddressBook: state.billingAddress['newAddress'] ? 1 : null,
          street: 'string' === typeof state.billingAddress['street'] ? state.billingAddress['street'].split(/\n/) : state.billingAddress['street'] || ['', '', ''],
          telephone: state.billingAddress['telephone'],
          extension_attributes: billingExtensionAttributes,
        };

    if (state.billingIsSameAsShipping) {
      billingAddress['saveInAddressBook'] = null;
    }

    return [billingAddress, shippingAddress];
  },
};

const mutations = {
  SET_BILLING_ADDRESS(state, billingAddress) {
    state.billingAddress = billingAddress;
    appLocalStorage.setItem('checkout-billing-address', state.billingAddress);
  },
  SET_SHIPPING_ADDRESS(state, shippingAddress) {
    state.shippingAddress = shippingAddress;
    appLocalStorage.setItem('checkout-shipping-address', state.shippingAddress);
  },
  SET_BILLING_IS_SAME_AS_SHIPPING(state, status) {
    appLocalStorage.setItem('checkout-billing-is-same-as-shipping', status);
    state.billingIsSameAsShipping = status;
  },
};

const actions = {
  setBillingAddress({ commit }, payload) {
    commit('SET_BILLING_ADDRESS', payload['address']);
  },
  setShippingAddress({ commit }, payload) {
    commit('SET_SHIPPING_ADDRESS', payload['address']);
  },
  toggleBillingIsSameAsShipping({ state, commit }) {
    commit('SET_BILLING_IS_SAME_AS_SHIPPING', !state.billingIsSameAsShipping);
  },
  async postcodeLookup({}, address): Promise<any> {
    const { data } = await this.$solarClient.get(`/api/address/autocomplete/details/${address['postcode']}/${address['houseNr']}/${address['houseNrAdd'] ?? ''}`);

    return data;
  },
};

const state = initialState();

const CheckoutAddress = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutAddress;
