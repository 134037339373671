
import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

@Component({
  name: 'category-content',
})

export default class extends Vue {
  $refs!: {
    categoryContent: HTMLElement;
  }

  @Prop() private title: string;
  @Prop() private content: string;
  @Prop() private categoryContent: object;

  @Watch('apiResponseCategoryContent')
  private handleApiResponseCategoryContentChange(): void {
    this.checkMeta(this.apiResponseCategoryContent);
  }

  private get apiResponseCategoryContent(): object {
    return this.$store.getters['CatalogCategory/getApiResponse']?.category_content || {};
  }

  private get contentToDisplay(): null | string {
    if (this.apiResponseCategoryContent.hasOwnProperty('content')) {
      return this.apiResponseCategoryContent['content'];
    }

    return this.content || '';
  }

  private get titleToDisplay(): string {
    return this.apiResponseCategoryContent['page_title'] || this.title || '';
  }

  private checkMeta(content: object): void {
    document.title = this.apiResponseCategoryContent['meta_title'] || content['meta_title'];

    this.addMeta(content['meta_title'], 'og:title');
    this.addMeta(content['meta_description'], 'og:description');
    this.addMeta(content['meta_description'], 'description', 'name');
    this.addMeta(window.location.href.split('?')[0], 'og:url');
  }

  private addMeta(value: string, meta: string, type: string = 'property'): void {
    if (value && meta) {
      const existingMetaOgTitle = document.head.querySelector(`meta[${type}="${meta}"]`);

      if (existingMetaOgTitle) {
        if (value === '') {
          existingMetaOgTitle.remove();
          return;
        }

        existingMetaOgTitle.setAttribute('content', value);
        return;
      }

      const metaOgTitle = document.createElement('meta');
      metaOgTitle.setAttribute(type, meta);
      metaOgTitle.setAttribute('content', value);

      document.head.appendChild(metaOgTitle);
    }
  }

  private created(): void {
    this.checkMeta(this.categoryContent);

    setTimeout(() => {
      const descElement = this.$refs.categoryContent.getElementsByClassName('filter__content')[0];
      const contentBlock = this.$refs.categoryContent.getElementsByClassName('_content')[0];
      const trueHeight = contentBlock.scrollHeight;
      const baseHeight = 75;

      if (trueHeight <= baseHeight) {
        (descElement.getElementsByClassName('_actions')[0] as HTMLElement).style.display = 'none';
      }

      descElement.querySelectorAll('._actions')[0].firstElementChild.addEventListener('click', function () {
        if (this.classList.contains('_active')) {
          this.classList.remove('_active');
          contentBlock.classList.add('_filter');
          this.innerText = 'Lees meer';
          (descElement.querySelectorAll('._content')[0] as HTMLElement).style.maxHeight = `${baseHeight}px`;
          window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
          this.classList.add('_active');
          contentBlock.classList.remove('_filter');
          this.innerText = 'Lees minder';
          (descElement.querySelectorAll('._content')[0] as HTMLElement).style.maxHeight = 'fit-content';
        }
      });
    }, 500);
  }
}
