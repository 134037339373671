const initialState = () => {
  return {
    searchStoreValue: ''
  };
};

const getters = {
  searchStoreValue: (state) => state.searchStoreValue,
};

const mutations = {
  SET_SEARCH_STORE_VALUE(state, payload) {
    state.searchStoreValue = payload;
  },
};

const actions = {
  async setSearchStore({commit}, payload) {
    commit('SET_SEARCH_STORE_VALUE', payload);
  }
};

const state = initialState();

const StoreLocator = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default StoreLocator;
