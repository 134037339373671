import { appSessionStorage } from '../services';

const initialState = () => {
  return {
    couponCode: appSessionStorage.getItem('checkout-payment-coupon-code', ''),
    couponStatus: null,
    couponMessage: '',
    couponRequestIsLoading: false,

    paymentMethods: [{}],
    paymentMethodConfig: [{}],
    loadingPaymentData: false,
    selectedPaymentMethod: appSessionStorage.getItem('checkout-payment-selected-method', {}),

    paymentDetails: appSessionStorage.getItem('checkout-payment-details', {}),
    shippingOptions: appSessionStorage.getItem('checkout-shipping-options', {}),
  };
};

const getters = {
  couponCode: (state) => state.couponCode,
  couponStatus: (state) => state.couponStatus,
  couponMessage: (state) => state.couponMessage,
  couponRequestIsLoading: (state) => state.couponRequestIsLoading,

  paymentMethods: (state) => state.paymentMethods,
  paymentMethodConfig: (state) => state.paymentMethodConfig,
  loadingPaymentData: (state) => state.loadingPaymentData,
  selectedPaymentMethod: (state) => state.selectedPaymentMethod,

  paymentDetails: (state) => state.paymentDetails,
  shippingOptions: (state) => state.shippingOptions,
};

const mutations = {
  SET_COUPON_CODE(state, couponCode) {
    state.couponCode = couponCode;
    appSessionStorage.setItem('checkout-payment-coupon-code', couponCode);
  },
  SET_COUPON_STATUS(state, couponStatus) {
    state.couponStatus = couponStatus;
  },
  SET_COUPON_MESSAGE(state, couponMessage) {
    state.couponMessage = couponMessage;
  },
  SET_COUPON_REQUEST_IS_LOADING(state, couponRequestIsLoading) {
    state.couponRequestIsLoading = couponRequestIsLoading;
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  SET_PAYMENT_METHOD_CONFIG(state, paymentMethodConfig) {
    state.paymentMethodConfig = paymentMethodConfig;
  },
  SET_LOADING_PAYMENT_DATA(state, loadingPaymentData) {
    state.loadingPaymentData = loadingPaymentData;
  },
  SET_SELECTED_PAYMENT_METHOD(state, selectedPaymentMethod) {
    state.selectedPaymentMethod = selectedPaymentMethod;
  },
  CLEAR_PAYMENT_DETAILS(state) {
    state.paymentDetails = {};
    appSessionStorage.removeItem('checkout-payment-details');
  },
  ADD_PAYMENT_DETAILS(state, paymentDetail) {
    state.paymentDetails = { ...state.paymentDetails, ...paymentDetail };
    appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
  },
  REMOVE_PAYMENT_DETAILS(state, paymentDetail) {
    if (state.paymentDetails.hasOwnProperty(paymentDetail)) {
      delete state.paymentDetails[paymentDetail];
    }
    appSessionStorage.setItem('checkout-payment-details', state.paymentDetails);
  },

  CLEAR_SHIPPING_OPTIONS(state) {
    state.shippingOptions = {};
    appSessionStorage.removeItem('checkout-shipping-options');
  },
  ADD_SHIPPING_OPTIONS(state, shippingOption) {
    state.shippingOptions = { ...state.shippingOptions, ...shippingOption };
    appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
  },
  REMOVE_SHIPPING_OPTIONS(state, shippingOption) {
    if (state.shippingOptions.hasOwnProperty(shippingOption)) {
      delete state[shippingOption];
    }
    appSessionStorage.setItem('checkout-shipping-options', state.shippingOptions);
  },
};

const actions = {
  async setSelectedPaymentMethod({ commit, dispatch }, paymentMethod) {
    commit('SET_SELECTED_PAYMENT_METHOD', paymentMethod);
    appSessionStorage.setItem('checkout-payment-selected-method', paymentMethod);
  },
  async setPaymentMethod({ state, rootState, rootGetters, dispatch, commit }, paymentMethod) {
    commit('CheckoutTotals/SET_TOTALS_AVAILABLE', false, { root: true });
    let additionalData = {};

    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

    try {
      await this.$solarClient.post(`/api/checkout/set-payment-information`, {
        email: rootGetters['CheckoutGlobal/customerEmail'],
        paymentMethod: {
          method: paymentMethod['code'],
          additional_data: additionalData,
        },
        billingAddress: _billingAddress,
      });

      // Collect totals.
      await dispatch('CheckoutTotals/fetchCartTotals', null, { root: true });
    } catch (err) {
      //
    }
  },
  async collectPaymentMethods({rootGetters, commit, dispatch}) {
    commit('SET_LOADING_PAYMENT_DATA', true);

    const {method_code, carrier_code} = rootGetters['CheckoutShipping/shippingMethod'];
    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];
    const _extensionAttributes = rootGetters['CheckoutGlobal/getExtensionAttributes'];

    const {data} = await this.$solarClient.post('/api/checkout/payment/collectPaymentMethods', {
      addressInformation: {
        shippingAddress: _shippingAddress,
        billingAddress: _billingAddress,
        shippingMethodCode: method_code,
        shippingCarrierCode: carrier_code,
        extensionAttributes: _extensionAttributes,
      },
    });

    commit('SET_PAYMENT_METHODS', data['payment_methods']);
    commit('SET_PAYMENT_METHOD_CONFIG', data['payment_config'] || {});
    if (data['payment_methods'].length > 0) {
      commit('SET_SELECTED_PAYMENT_METHOD', data['payment_methods'][0]);
    }
    commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], {root: true});
    commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
      root: true,
    });
    commit('SET_LOADING_PAYMENT_DATA', false);
  },

  async placeOrder({ rootGetters, getters, dispatch }) {
    const [_billingAddress] = rootGetters['CheckoutAddress/getAddressData'];

    return await this.$mageClient.post('/api/checkout/payment/placeOrder', {
      email: rootGetters['CheckoutGlobal/customerEmail'],
      shippingOptions: getters.shippingOptions,
      paymentMethod: {
        method: getters.selectedPaymentMethod['code'],
        additional_data: getters.paymentDetails,
      },
      billingAddress: _billingAddress,
    });
  },

  async getCouponCode({ rootState, rootGetters, commit }) {
    try {
      const { data } = await this.$mageClient.get('/api/checkout/payment/coupons');

      if (data.length) {
        commit('SET`_COUPON_CODE', data);
        commit('SET_COUPON_STATUS', true);
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },
  async applyCouponCode({ commit }, couponCode) {
    commit('SET_COUPON_STATUS', false);
    commit('SET_COUPON_REQUEST_IS_LOADING', true);
    try {
      const { data } = await this.$mageClient.put(`/api/checkout/payment/coupons/${couponCode}`);

      if (data['couponStatus']) {
        commit('SET_COUPON_CODE', couponCode);
        commit('SET_COUPON_STATUS', true);
      }

      commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], { root: true });
      commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
        root: true,
      });
    } catch ({ response }) {
      commit('SET_COUPON_MESSAGE', response.data.message);
      commit('SET_COUPON_STATUS', false);
    } finally {
      commit('SET_COUPON_REQUEST_IS_LOADING', false);
    }
  },
  async removeCouponCode({ commit }) {
    commit('SET_COUPON_REQUEST_IS_LOADING', true);
    try {
      const { data } = await this.$mageClient.delete(`/api/checkout/payment/coupons`);
      commit('SET_COUPON_CODE', '');
      commit('SET_COUPON_STATUS', null);

      commit('CheckoutTotals/SET_CART_TOTALS', data['totals'], { root: true });
      commit('CheckoutTotals/SET_CART_ITEMS', data['totals']['items'], {
        root: true,
      });
    } catch (e) {
      commit('SET_COUPON_MESSAGE', 'The coupon code could not be cancelled, please try again later.');
    } finally {
      commit('SET_COUPON_REQUEST_IS_LOADING', false);
    }
  },
};

const state = initialState();

const CheckoutPayment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutPayment;
